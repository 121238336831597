var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view-item"},[_c('div',{staticClass:"add-item-left",on:{"click":_vm.openAddCard}}),(this.cardId === 0 ||
   (this.cardId !== 0))?_c('div',{key:_vm.store.state.questionTitle,staticClass:"add-item-window"},[_c('form',{staticClass:"form",staticStyle:{"width":"100%"},attrs:{"id":"add_dep_form"},on:{"submit":function($event){$event.preventDefault();return (() => {
      _vm.addCardFunc()
    }).apply(null, arguments)}}},[_c('div',{key:this.cardId,staticStyle:{"height":"100vh"}},[_c('div',{key:_vm.store.state.questionTitle,staticClass:"menu"},[_c('unicon',{attrs:{"name":"multiply","fill":"#828282","height":"24","width":"24"},on:{"click":_vm.openAddCard}}),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(this.cardId !== 0 ? _vm.store.state.questionTitle : _vm.$t('TAGS.ADD_CARD'))+" ")]),_c('BaseButton',{staticClass:"float-end",attrs:{"variant":'primary',"type":"submit"}},[(this.loading === true)?_c('div',[_c('unicon',{attrs:{"fill":"white","height":"16","width":"16","name":"spinner-alt"}})],1):_vm._e(),(this.loading === false)?_c('div',[_c('unicon',{attrs:{"fill":"white","height":"16","width":"16","name":"save"}}),_vm._v(" "+_vm._s(_vm.$t('GENERAL.SAVE'))+" ")],1):_vm._e()])],1),_c('div',{staticClass:"content"},[_c('div',[_c('div',{staticStyle:{"display":"inline-flex","width":"100%","padding":"0 24px","margin-bottom":"24px"}},[_c('div',{staticStyle:{"width":"-webkit-fill-available"}},[_c('div',{staticClass:"custom-input"},[_c('div',{staticClass:"title-2-bold-20 basic-2"},[_vm._v(" "+_vm._s(_vm.$t('TAGS.FIELDS.QUESTION_TITLE'))+" ")]),_c('div',{staticClass:"custom-input recorder"},[_c('textarea',{attrs:{"type":"text","id":_vm.store.state.questionTitle,"placeholder":_vm.$t('TAGS.FIELDS.QUESTION_TITLE'),"required":"","maxlength":"200"},domProps:{"value":_vm.store.state.questionTitle},on:{"change":(e) => _vm.store.state.questionTitle = e.target.value}}),_c('Recorder',{attrs:{"onChange":(value,rec) => {
                        _vm.store.state.questionTitle = value
                      }}})],1),_c('div',{staticClass:"upload-question-photo",staticStyle:{"position":"absolute","top":"84px","height":"40px","right":"0","width":"40px"}},[_c('input',{ref:"uploadInput",staticStyle:{"opacity":"0","width":"40px","height":"40px","z-index":"100","position":"relative"},attrs:{"type":"file","accept":"image/*","multiple":""},on:{"change":_vm.previewImgs}}),_c('unicon',{staticStyle:{"z-index":"0","position":"absolute","top":"8px","left":"8px"},attrs:{"name":"image-plus","width":"24","height":"24","fill":"#333333"}})],1)]),(this.Img.length !== 0)?_c('div',{staticStyle:{"position":"relative"}},[_c('img',{staticStyle:{"max-width":"100%","margin-top":"20px"},attrs:{"src":this.Img[0],"alt":""}}),_c('div',{staticClass:"delete-card",staticStyle:{"left":"unset","right":"0"},on:{"click":function($event){_vm.Img = []}}},[_c('unicon',{attrs:{"name":"trash-alt","fill":"#BDBDBD","height":"18","width":"18"}})],1)]):_vm._e()])]),(_vm.store.state.type !== 'options')?_c('div',{staticStyle:{"display":"inline-flex","width":"100%","padding":"0 24px","margin-bottom":"24px"}},[_c('div',{staticStyle:{"width":"-webkit-fill-available"}},[_c('div',{staticClass:"custom-input"},[_c('div',{staticClass:"title-2-bold-20 basic-2"},[_vm._v(" "+_vm._s(_vm.$t('TAGS.FIELDS.ANSWER'))+" ")]),_c('div',{staticClass:"custom-input recorder"},[_c('textarea',{attrs:{"type":"text","id":_vm.store.state.answers[0],"placeholder":_vm.$t('TAGS.FIELDS.ANSWER'),"required":"","maxlength":"200"},domProps:{"value":_vm.store.state.answers[0].answer},on:{"change":(e) => _vm.store.state.answers[0].answer = e.target.value}}),_c('Recorder',{attrs:{"onChange":(value,rec) => {
                        _vm.store.state.answers[0].answer = value
                      }}})],1)])])]):_vm._e(),(_vm.store.state.type === 'options')?_c('div',{staticStyle:{"display":"inline-flex","width":"100%","padding":"0 24px","margin-bottom":"24px"}},[_c('div',{staticStyle:{"width":"-webkit-fill-available"}},[_c('div',{staticClass:"custom-input"},[_c('div',{staticClass:"title-2-bold-20 basic-2"},[_vm._v(" "+_vm._s(_vm.$t('TAGS.FIELDS.ANSWER'))+" ")])]),_vm._l((_vm.store.state.answers),function(answer,j){return _c('div',{key:j,staticClass:"custom-input"},[_c('div',{staticClass:"d-flex",staticStyle:{"margin-bottom":"10px"}},[_c('div',{staticClass:"d-inline-flex",staticStyle:{"width":"-webkit-fill-available"}},[_c('div',{staticClass:"custom-input"},[(answer.is_right_answer)?_c('label',[_vm._v(" "+_vm._s(_vm.$t('TAGS.ANSWER_RIGHT'))+" ")]):_vm._e(),(!answer.is_right_answer)?_c('label',[_vm._v(" "+_vm._s(_vm.$t('TAGS.ANSWER_WRONG'))+" ")]):_vm._e(),_c('div',{staticClass:"custom-input recorder"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(answer.answer),expression:"answer.answer"}],attrs:{"type":"text","id":_vm.fields.answer.name,"placeholder":_vm.fields.answer.label,"required":"","maxlength":"200"},domProps:{"value":(answer.answer)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(answer, "answer", $event.target.value)}}}),_c('Recorder',{attrs:{"onChange":(value,rec) => {
                        _vm.store.state.answers[0].answer = value
                      }}})],1)])]),(j !== 0)?_c('div',{staticClass:"d-inline-flex"},[_c('unicon',{staticStyle:{"margin":"40px 0 0 8px","cursor":"pointer","left":"unset","right":"8px","top":"-4px"},attrs:{"name":"trash-alt","height":"18","width":"18","fill":"#EB5757"},on:{"click":function($event){return _vm.store.state.answers.splice(j,1)}}})],1):_vm._e()])])}),(_vm.store.state.answers.length < 5)?_c('BaseButton',{staticStyle:{"width":"100%","justify-content":"center"},attrs:{"variant":"light","callback":this.newAnswer,"callback-params":0}},[_c('unicon',{staticStyle:{"margin-right":"12px","margin-top":"-2px"},attrs:{"name":"plus","width":"16","height":"16","fill":"#1694D0"}}),_vm._v(" "+_vm._s(_vm.$t('TAGS.ADD_ANSWER'))+" ")],1):_vm._e()],2)]):_vm._e()])])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }