<script>
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import Vue from 'vue';
import VueChatScroll from 'vue-chat-scroll';
import BaseButton from '../../../../shared/components/BaseButton.vue';
import QuestionCard from '../../../../shared/cards/QuestionCard.vue';
import AddCardToFile from '../../../../shared/components/AddCardToFile.vue';

Vue.use(VueChatScroll);

const store = {
  state: {
    loading: false,
    pdf: true,
    file_name: '',
  },
};
export default {
  components: {
    BaseButton,
    QuestionCard,
    AddCardToFile,
  },
  data() {
    return {
      store,
      tag: '',
      file_id: 0,
      tag_category: '',
      loading: false,
      cards_generated: false,
      cards: [],
      cardId: 0,
    };
  },
  methods: {
    getFile() {
      const getFilePath = '/WebOpenaiGetPdfDetails.php';
      const data = new FormData();
      data.append('file_id', this.$route.params.id);

      fetch(server + getFilePath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          if (json.error.includes('Wrong token')) {
            this.$router.push({ name: 'login' });
            destroyToken();
          } else {
            Notify('warning', json.error);
          }
        }
        if (json.name !== '') {
          this.file_id = json.id;
          this.tag = json.tag;
          this.tag_category = json.tag_category;
          this.loading = json.generate_scheduled && !json.generate_done;
          this.cards_generated = json.generate_done;
          this.cards = json.questions;
        }
      });
    },
    generateCards() {
      const listCatPath = '/WebOpenaiProcessPdf.php';
      this.loading = true;

      const data = new FormData();
      data.append('file_id', this.file_id);
      data.append('tag', this.tag);
      data.append('tag_category', this.tag_category);
      fetch(server + listCatPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
          'Accept-language': window.localStorage.getItem('language'),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          if (json.error.includes('Wrong token')) {
            this.$router.push({ name: 'login' });
            destroyToken();
          } else {
            Notify('warning', json.error);
          }
        }
      });
    },
    openAddCart(title) {
      const addCardWindow = document.querySelector('.view-item');
      store.state.tagTitleForCard = title ?? '';
      addCardWindow.classList.toggle('show');
      if (addCardWindow.classList.value.includes('hide')) {
        addCardWindow.classList.toggle('hide');
      } else {
        setTimeout(() => {
          addCardWindow.classList.toggle('hide');
        }, 300);
      }
    },
    editCard(card) {
      this.cardId = card.id;
      setTimeout(() => {
        this.openAddCart();
      }, 300);
    },
    addCard() {
      this.cardId = 0;
      setTimeout(() => {
        this.openAddCart();
      }, 300);
    },
  },
  beforeMount() {
    this.getFile();
    setInterval(() => {
      if (this.loading) {
        this.getFile();
      }
    }, 10000);
  },
};
</script>
<template lang="html">
  <div>
    <b-row v-if="tag_category !== '' && tag !== '' && cards.length === 0">
      <b-col lg="12" style="padding: 0;">

        <form class="form" v-on:submit.prevent="generateCards(1)"
              id="add_dep_form" style="width: 100%;">
          <div class="headline-bold-16 basic-1" style="display: flex; justify-content: center">
            {{ $t("FILE_AI.LEARNING_CARDS_INFO") }}
          </div>
          <div class="custom-input">
            <label>
              {{ $t("TAGS.SUBJECT") }}
            </label>
            <input type="text"
                   v-model="tag"
                   required
                   maxlength="200"
            >
          </div>
          <div class="custom-input">
            <label>
              {{ $t("TAGS.CATEGORY") }}
            </label>
            <input type="text"
                   v-model="tag_category"
                   required
                   maxlength="200"
            >
          </div>
          <div style="display: flex; justify-content: center; width: 100%"
               v-if="loading === false">
            <BaseButton
                style="margin-top: 20px"
                :variant="'primary'"
                type="submit">
              <img src="/media/learning_cards_white.svg" alt="" style="width: 20px;
                   margin-top: -2px"/>
              {{$t("FILE_AI.GENERATE_LEARNING_CARD")}}
            </BaseButton>
          </div>
        </form>
      </b-col>
    </b-row>
    <b-row v-if="loading === true">
      <b-col lg="12" style="padding: 0; margin-top: 20px">

        <div>
          <div style="display: flex; justify-content: center">
            <img src="/media/loading_generate_ai_cards.svg" alt=""/>
          </div>
          <div style="display: flex; justify-content: center">
            <div class="headline-bold-16">{{ $t("FILE_AI.GENERATING_LEARNING_CARDS") }}</div>
          </div>
          <div style="display: flex; justify-content: center">
            <div class="caption-1-reg-13">
              {{ $t("FILE_AI.GENERATING_LEARNING_CARDS_DESCRIPTION") }}</div>
          </div>
        </div>
      </b-col>
    </b-row>
    <div v-if="cards_generated === true && cards.length > 0">
      <div class="title-card" style="margin-bottom: 16px">
        <b-row>
          <b-col md="6">
            <div class="headline-bold-16"
                 style="display: flex; height: 100%;
          vertical-align: center; align-items: center">
              {{ $t("FILE_AI.CARDS_GENERATED_1") }} {{cards.length}}
              {{ $t("FILE_AI.CARDS_GENERATED_2") }}
            </div>
          </b-col>
          <b-col md="6" style="padding-right: 0">
            <BaseButton style="float: right"
                        :callback="addCard"
                        :callback-params="0">
              <img src="/media/learning_cards_white.svg" alt="" style="width: 20px;
                   margin-top: -2px"/>
              {{ $t('FILE_AI.ADD_LEARNING_CARD') }}
            </BaseButton>
          </b-col>
        </b-row>
      </div>
      <div class="questions-container">
        <div v-for="(question,i) in cards" :key="i">
          <QuestionCard
              :question="question"
              :index="i"
              :callback="getFile"
              :callback-params="0"
              :callback2="editCard"
              :callback-params2="question"
          />
        </div>
      </div>

      <div class="view-item hide">
        <AddCardToFile :key="this.cardId"
                 :file-id="this.file_id"
                 :tag="this.tag"
                 :card-id="this.cardId"
                 :callback="() => {
                 this.getFile();
               }"
        >
        </AddCardToFile>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
</style>
