import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';

const label = (name) => i18n.t(`TAGS.FIELDS.${name.toUpperCase()}`);

const fields = {
  question_title: new StringField('question_title', label('question_title'), {
    required: true,
  }),
  answer: new StringField('answer', label('answer'), {
    required: true,
  }),
  answers: Array([]),
};

export default class AddCardModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
