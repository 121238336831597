import { render, staticRenderFns } from "./file-ai-learning-cards.vue?vue&type=template&id=f8cc1646&lang=html"
import script from "./file-ai-learning-cards.vue?vue&type=script&lang=js"
export * from "./file-ai-learning-cards.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports