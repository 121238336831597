<script>
import Recorder from '@/shared/components/Recorder.vue';
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import AddCardModel from '@/modules/tags/add-card-model';
import BaseButton from '@/shared/components/BaseButton.vue';
import i18n from '../plugins/vue-i18n';

const { fields } = AddCardModel;
const store = {
  state: {
    type: 'flash',
    questionTitle: '',
    answers: [{
      answer: '',
      is_right_answer: true,
    }],
  },
};
export default {
  name: 'AddCartToFile',
  components: {
    BaseButton,
    Recorder,
  },
  props: {
    cardId: {
      type: Number,
      required: true,
      default: 0,
    },
    tag: {
      type: String,
    },
    fileId: {
      type: Number,
      required: true,
      default: 0,
    },
    callback: Function,
    // Defines a store action as string which can be used to update parent state on change event
  },
  data() {
    return {
      fields,
      store,
      model: {},
      Img: [],
      loading: false,
      id: null,
      info: null,
    };
  },
  methods: {
    Notify() {
      return Notify;
    },
    openAddCard() {
      const addPollWindow = document.querySelector('.view-item');
      addPollWindow.classList.toggle('show');
      if (addPollWindow.classList.value.includes('hide')) {
        addPollWindow.classList.toggle('hide');
      } else {
        setTimeout(() => {
          addPollWindow.classList.toggle('hide');
        }, 300);
      }
    },
    readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = function y() {
          resolve(fr.result);
        };
        fr.onerror = function z() {
          reject(fr);
        };
        fr.readAsDataURL(file);
      });
    },
    addCardFunc() {
      let addCardPath = '';
      const data = new FormData();
      if (parseInt(this.cardId, 10) !== 0) {
        addCardPath = '/WebEditQuestion.php';
        data.append('id', this.cardId);
      } else {
        addCardPath = '/WebAddQuestion.php';
        data.append('file_id', this.fileId);
      }
      if (this.tag) {
        data.append('tags[]', this.tag);
      }
      data.append('question', store.state.questionTitle);
      data.append('type', store.state.type);
      store.state.answers.forEach((answer, i) => {
        data.append(`answers[${i}][answer]`, answer.answer);
        if (answer.right !== undefined) {
          data.append(`answers[${i}][right]`, answer.right);
        } else {
          data.append(`answers[${i}][right]`, answer.is_right_answer);
        }
      });
      data.append('image', this.Img[0] ? this.Img[0] : '');

      fetch(server + addCardPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.status === 'ok') {
            Notify('success', i18n.t('GENERAL.SAVED_SUCCESSFULLY'));
            this.callback();
            this.openAddCard();
          }
        });
    },
    getCard(id) {
      const getCardPath = '/WebGetQuestionDetails.php';
      const data = new FormData();
      data.append('id', id);

      fetch(server + getCardPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.title !== '') {
            this.info = json;
            store.state.questionTitle = json.title;
            store.state.type = json.type;
            if (json.image) {
              this.Img[0] = json.image;
            } else {
              this.Img = [];
            }

            store.state.answers = json.answers ?? [];
          }
        });
    },
    newAnswer() {
      store.state.answers.push({
        answer: '',
        is_right_answer: false,
      });
    },
    previewImgs(event) {
      this.files = event.currentTarget.files;
      this.error = '';
      this.$emit('changed', this.files);
      const readers = [];
      if (!this.files.length) return;
      for (let i = 0; i < this.files.length; i += 1) {
        readers.push(this.readAsDataURL(this.files[i]));
      }
      Promise.all(readers).then((values) => {
        this.Img = values;
      });
    },
  },
  beforeMount() {
    if (this.cardId !== 0) {
      if (this.id !== this.cardId) {
        this.getCard(this.cardId);
        this.id = this.cardId;
      }
    } else if (this.id !== 0) {
      this.info = null;
      store.state.questionTitle = '';
      store.state.type = 'flash';
      this.Img = [];
      store.state.answers = [{
        answer: '',
        is_right_answer: true,
      }];
      this.id = 0;
    }
  },
  beforeUpdate() {
    if (this.cardId !== 0) {
      if (this.id !== this.cardId) {
        this.getCard(this.cardId);
        this.id = this.cardId;
      }
    } else if (this.id !== 0) {
      this.info = null;
      store.state.questionTitle = '';
      store.state.type = 'flash';
      this.Img = [];
      store.state.answers = [{
        answer: '',
        is_right_answer: true,
      }];
      this.id = 0;
    }
  },
};
</script>
<template>
  <div class="view-item">
    <div class="add-item-left" v-on:click="openAddCard">
    </div>
    <div class="add-item-window" v-if="this.cardId === 0 ||
     (this.cardId !== 0)" :key="store.state.questionTitle">
      <form class="form" v-on:submit.prevent="() => {
        addCardFunc()
      }" id="add_dep_form" style="width: 100%;">

        <div style="height: 100vh" :key="this.cardId">
          <div class="menu" :key="store.state.questionTitle">
            <unicon name="multiply" fill="#828282" height="24" width="24"
                    v-on:click="openAddCard"/>
            <div class="title">
              {{ this.cardId !== 0 ? store.state.questionTitle : $t('TAGS.ADD_CARD') }}
            </div>
            <BaseButton
              class="float-end"
              :variant="'primary'"
              type="submit"
            >
              <div v-if="this.loading === true">
                <unicon fill="white" height="16" width="16" name="spinner-alt"/>
              </div>
              <div v-if="this.loading === false">
                <unicon fill="white" height="16" width="16" name="save"/>
                {{ $t('GENERAL.SAVE') }}
              </div>
            </BaseButton>
          </div>
          <div class="content">
            <div >
              <div style="display: inline-flex;width: 100%;padding: 0 24px; margin-bottom: 24px">
                <div style="width: -webkit-fill-available;">
                  <div class="custom-input">
                    <div class="title-2-bold-20 basic-2">
                      {{ $t('TAGS.FIELDS.QUESTION_TITLE') }}
                    </div>
                    <div class="custom-input recorder">
                    <textarea type="text"
                              :id="store.state.questionTitle"
                              :value="store.state.questionTitle"
                              v-on:change="(e) => store.state.questionTitle = e.target.value"
                              :placeholder="$t('TAGS.FIELDS.QUESTION_TITLE')"
                              required
                              maxlength="200"

                    />

                      <Recorder
                        :onChange="(value,rec) => {
                          store.state.questionTitle = value
                        }"
                      />
                    </div>
                    <div class="upload-question-photo"
                         style="position: absolute;top: 84px;height: 40px;
                      right: 0; width: 40px">
                      <input
                        style="opacity: 0;width: 40px; height: 40px;
                         z-index: 100; position: relative"
                        type="file"
                        accept="image/*"
                        ref="uploadInput"
                        @change="previewImgs"
                        multiple
                      />
                      <unicon style="z-index: 0; position: absolute; top:8px; left:8px"
                              name="image-plus"
                              width="24" height="24" fill="#333333"/>
                    </div>
                  </div>
                  <div style="position: relative" v-if="this.Img.length !== 0">
                    <img :src="this.Img[0]" alt=""
                         style="max-width: 100%;margin-top: 20px"/>
                    <div class="delete-card" v-on:click="Img = []" style="left: unset; right: 0">
                      <unicon name="trash-alt" fill="#BDBDBD" height="18" width="18"/>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: inline-flex;width: 100%;padding: 0 24px; margin-bottom: 24px"
                   v-if="store.state.type !== 'options'"
              >
                <div style="width: -webkit-fill-available;">
                  <div class="custom-input">
                    <div class="title-2-bold-20 basic-2">
                      {{ $t('TAGS.FIELDS.ANSWER') }}
                    </div>
                    <div class="custom-input recorder">
                    <textarea type="text"
                              :id="store.state.answers[0]"
                              :value="store.state.answers[0].answer"
                              v-on:change="(e) => store.state.answers[0].answer = e.target.value"
                              :placeholder="$t('TAGS.FIELDS.ANSWER')"
                              required
                              maxlength="200"
                    />

                      <Recorder
                        :onChange="(value,rec) => {
                          store.state.answers[0].answer = value
                        }"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: inline-flex;width: 100%;padding: 0 24px; margin-bottom: 24px"
                   v-if="store.state.type === 'options'">
                <div style="width: -webkit-fill-available;">
                  <div class="custom-input">
                    <div class="title-2-bold-20 basic-2">
                      {{ $t('TAGS.FIELDS.ANSWER') }}
                    </div>
                  </div>
                  <div class="custom-input" v-for="(answer, j) in store.state.answers" :key="j">
                    <div class="d-flex"
                         style="margin-bottom: 10px">
                      <div class="d-inline-flex" style="width: -webkit-fill-available;">
                        <div class="custom-input">
                          <label v-if="answer.is_right_answer">
                            {{ $t('TAGS.ANSWER_RIGHT') }}
                          </label>
                          <label v-if="!answer.is_right_answer">
                            {{ $t('TAGS.ANSWER_WRONG') }}
                          </label>
                          <div class="custom-input recorder">
                          <textarea type="text"
                                    :id="fields.answer.name"
                                    v-model="answer.answer"
                                    :placeholder="fields.answer.label"
                                    required
                                    maxlength="200"
                          />

                            <Recorder
                              :onChange="(value,rec) => {
                          store.state.answers[0].answer = value
                        }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="d-inline-flex" v-if="j !== 0">
                        <unicon name="trash-alt" height="18" width="18" style="margin: 40px 0 0 8px;
                        cursor: pointer; left: unset;right: 8px;top: -4px"
                                fill="#EB5757" v-on:click="store.state.answers.splice(j,1)"/>
                      </div>
                    </div>
                  </div>
                  <BaseButton style="width: 100%;justify-content: center" variant="light"
                              v-if="store.state.answers.length < 5"
                              :callback="this.newAnswer" :callback-params="0">
                    <unicon name="plus" width="16" height="16"
                            style="margin-right: 12px;margin-top: -2px" fill="#1694D0"/>
                    {{ $t('TAGS.ADD_ANSWER') }}
                  </BaseButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

    </div>
  </div>
</template>

<style>
.view-item .delete-card {
  padding: 6px;
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  border-radius: 8px;
  width: fit-content;
  cursor: pointer;
  position: absolute;
  left: 8px;
  top: 28px;
}

.view-item .type-selector {
  background: #FFFFFF;
  box-shadow: 0px 0px 10.5622px rgba(0, 0, 0, 0.2);
  border-radius: 9.38863px;
  width: 100%;
  height: 124px;
  padding: 24px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16.4301px;
  line-height: 21px;
  text-align: center;
}

.view-item .type-selector .unicon {
  display: block;
}

.view-item .type-selector svg {
  margin-top: 8px;
  margin-right: 0;
}

.view-item .type-selector.active {
  border: 1.17358px solid #04D1AC;
}

.view-item .custom_select button, .view-item .custom_select .dropdown.b-dropdown.btn-group {
  margin-top: 0;
  height: 43px !important;
}

.upload-question-photo {
  position: absolute;
  right: 8px;
}

.upload-question-photo input {
  width: 24px;
  background: transparent;
}

.upload-question-photo .unicon {
  position: absolute;
  top: -65px;
  left: -24px;
}

</style>
